import React from "react";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InquiryForm from "./InqueryForm/Inquery_form";

const Side_quick = () => {
    return (
        <>
          <div className="pos_sticky">
                <div className="quikform">
                    <h5>Quick Enquiry</h5>
                    <Row>
                        <InquiryForm />
                    </Row>
                </div>

                <div className="twobtnswrp">
                    <a href="tel:+91-9054865866" className="ylwbtn">Call @+91-9054865866</a>
                    <a href="https://wa.me/919054865866" target="_blank" rel="noopener noreferrer" className="wtspbtn"><WhatsAppIcon/> Whatsapp</a>
                </div>
          </div>
        </>
    )
}
export default Side_quick;
