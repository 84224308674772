import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Col, Button, Form as BootstrapForm } from 'react-bootstrap';
import axios from 'axios';
import Config from '../Config/Config';
import { AlertContext } from '../Context/AlertsPopusContext';
import randomString from "randomstring";
import { FiRefreshCcw } from "react-icons/fi";


const validationSchema = Yup.object({
  fullName: Yup.string()
    .required('Full Name is required')
    .min(2, 'Full Name must be at least 2 characters'),
  mobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 digits'),
  query: Yup.string()
    .required('Your query is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
  captureText: Yup.string()
    .required("Please Add the Capture")
});





const InquiryForm = () => {
  const { setMessage, setStatus } = useContext(AlertContext);
  const [captchaText, setCaptchaText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [error, setError] = useState("");
  const canvasRef = useRef(null);
  const [queryMaster, setQueryMaster] = useState([]);

  const generateCaptchaText = () => {
    return randomString.generate({ length: 5, charset: "alphanumeric" });
  };


  const getAllqueryFromAdmin = async () => {
    try {

      let payloads = {
        "type":'seo'
      }

      let response = await axios.post(`${Config.API_URL}/api/customer/query-master`, payloads);


      if (response?.data?.status) {
        setQueryMaster(response.data?.data)
      }

    } catch (error) {

      setQueryMaster([])

    }
  }



  const generateCaptchaImage = () => {
    const text = generateCaptchaText();
    setCaptchaText(text);

    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let frame = 0;

    const drawFrame = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Subtle dark-to-light gradient background
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
      gradient.addColorStop(0, "rgba(20, 20, 20, 0.8)");
      gradient.addColorStop(1, "rgba(60, 60, 60, 0.8)");
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Random noise (subtle grayscale dots)
      for (let i = 0; i < 100; i++) {
        ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.1})`;
        ctx.beginPath();
        ctx.arc(Math.random() * canvas.width, Math.random() * canvas.height, Math.random() * 2, 0, Math.PI * 2);
        ctx.fill();
      }

      // Light gray distorted lines for added difficulty
      for (let i = 0; i < 3; i++) {
        ctx.strokeStyle = `rgba(255, 255, 255, 0.2)`;
        ctx.lineWidth = 1;
        ctx.beginPath();
        let y = Math.sin(frame * 0.02 + i) * 5 + canvas.height / 2;
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y + Math.sin(frame * 0.05) * 8);
        ctx.stroke();
      }

      // Subtly distorted CAPTCHA text
      ctx.font = "bold 26px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(Math.sin(frame * 0.03) * 0.1); // Gentle distortion
      ctx.fillText(text, 0, 8);
      ctx.restore();

      frame++;
      requestAnimationFrame(drawFrame);
    };

    drawFrame();
  };

  useEffect(() => {

    generateCaptchaImage()
    getAllqueryFromAdmin()

  }, [])

  return (
    <Formik
      initialValues={{ fullName: '', mobileNumber: '', query: '', email: '', captureText: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setFieldError }) => {

        if (values?.captureText !== captchaText) {
          setFieldError('captureText', 'Captcha does not match');
          generateCaptchaText()
          return;
        }

        axios.post(`${Config.API_URL}/api/customer/inquiry/add_inquiry`, values)
          .then((response) => {
            if (response.data.status) {
              resetForm();

              setMessage({
                status: true,
                message: response.data.message
              })

              setStatus(true);

              const timer = setTimeout(() => {
                setStatus(false);
              }, 3000);

              return () => clearTimeout(timer);
            }
          })
          .catch((err) => {
            console.log(err.message);
          })
      }}
    >
      {({ handleSubmit, touched, errors }) => (
        <BootstrapForm onSubmit={handleSubmit}>
          <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="fullName">
              <Field
                name="fullName"
                type="text"
                placeholder="Enter your name"
                className={`form-control ${touched.fullName && !errors.fullName ? 'is-valid' : ''} ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
              />
              <ErrorMessage name="fullName" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col>
          <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="email">
              <Field
                name="email"
                type="email"
                placeholder="Enter your email address"
                className={`form-control ${touched.email && !errors.email ? 'is-valid' : ''} ${touched.email && errors.email ? 'is-invalid' : ''}`}
                required
              />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col>
          <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="mobileNumber">
              <Field
                name="mobileNumber"
                type="text"
                placeholder="Enter your mobile number"
                className={`form-control ${touched.mobileNumber && !errors.mobileNumber ? 'is-valid' : ''} ${touched.mobileNumber && errors.mobileNumber ? 'is-invalid' : ''}`}
                required
              />
              <ErrorMessage name="mobileNumber" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col>
          {/* <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="query">
              <Field
                name="query"
                as="textarea"
                placeholder="Your message"
                rows={4}
                className={`form-control ${touched.query && !errors.query ? 'is-valid' : ''} ${touched.query && errors.query ? 'is-invalid' : ''}`}
              />
              <ErrorMessage name="query" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col> */}

          <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="query">
              <Field
                as="select"
                name="query"
                className={`form-control ${touched.query && !errors.query ? 'is-valid' : ''} ${touched.query && errors.query ? 'is-invalid' : ''}`}
              >
                <option value="">Subject</option>
                {queryMaster?.length < 0 ? <option value="">No option Available</option> :
                  queryMaster.map((query, index) => (
                    <option key={index} value={query.text}>{query.text}</option>
                  ))}

              </Field>
              <ErrorMessage name="query" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col>


          <Col xs={12} md={12} className='dflxbtwn'>
            <canvas ref={canvasRef} width="150" height="40" style={{ border: "1px solid black" }}></canvas>

            <div>
              <button type="button" onClick={generateCaptchaImage} className="btn btn-secondary btn-sm">
                <FiRefreshCcw />
              </button>
            </div>

          </Col>

          <Col xs={12} md={12}>
            <BootstrapForm.Group className="mb-3" controlId="mobileNumber">
              <Field
                name="captureText"
                type="text"
                placeholder="Enter the Capture"
                className={`form-control ${touched.captureText && !errors.captureText ? 'is-valid' : ''} ${touched.captureText && errors.captureText ? 'is-invalid' : ''}`}
                required
              />
              <ErrorMessage name="captureText" component="div" className="text-danger" />
            </BootstrapForm.Group>
          </Col>

          <Col xs={12} md={12}>
            <Button className="sitebtn" type="submit">
              Submit
            </Button>
          </Col>
        </BootstrapForm>
      )}
    </Formik>
  );
};

export default InquiryForm;
