import React, { useEffect, useState, useRef, useCallback } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import Config from "./Config/Config";

const dropdownVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

const DroupDwonSuggetions = ({ TargetInput, trip_type, handleSuggestionClick }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const timerRef = useRef(null);
  const dropdownRef = useRef(null);

  const calledCityAPI = useCallback((key, checkedValue) => {
    const payloads = {
      keyword: key,
      page_no: "1",
      records_per_page: "10",
      trip_type: checkedValue
    };

    setIsLoading(true);
    axios
      .post(`${Config.API_URL}/api/customer/citylist`, payloads, {
        headers: {
          Authorization: `${Config.API_ACCESS_TOKEN}`
        }
      })
      .then((response) => {
        if (response.data.status) {
          setApiResponse(response.data.data);
          setHighlightedIndex(0); // Reset highlighted index on new data
        } else {
          setApiResponse([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setApiResponse([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // Debounce API call for 300ms
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
        calledCityAPI(TargetInput, trip_type);
      
    }, 300);

    return () => clearTimeout(timerRef.current);
  }, [TargetInput, trip_type, calledCityAPI]);

  const handleKeyDown = (e) => {
    if (!apiResponse.length) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev + 1) % apiResponse.length);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prev) =>
        prev === 0 ? apiResponse.length - 1 : prev - 1
      );
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (apiResponse[highlightedIndex]) {
        handleSuggestionClick(apiResponse[highlightedIndex]);
      }
    } else if (e.key === "Escape") {
      // Optionally add behavior for Escape key if needed
    }
  };

  return (
    <AnimatePresence>
      {(
        <motion.div
          ref={dropdownRef}
          tabIndex={0} // Makes the div focusable so it can capture key events
          onKeyDown={handleKeyDown}
          variants={dropdownVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="position-absolute p-2 w-100 custom-scrollbar"
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            zIndex: 999999999,
            width: "100%",
            maxHeight: "300px",
            overflowY: "auto"
          }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center p-3">
              <Spinner animation="border" size="sm" />
            </div>
          ) : apiResponse.length > 0 ? (
            apiResponse.map((value, index) => (
              <>
              <div
                  key={value._id}
                  onMouseDown={() => handleSuggestionClick(value)}
                  className={`p-2 cursor-pointer ${index === highlightedIndex ? "bg-primary text-white" : "hover:bg-light"}`}
              >
                {value.city_name}, {value.state_name}
              </div>
              <hr />
              </>
            ))
          ) : (
            <div className="p-3 text-muted">No results found</div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DroupDwonSuggetions;
