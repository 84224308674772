import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "./Config/Config";
import quote from './images/quote.png';
import { Link } from "react-router-dom";

const Cms_review = ({ reviews }) => {
    
    const [reviewsList, setReviewsList] = useState([]);
    const handle_reviews_api = async () => {
        try {
            const response = await axios.get(`${Config.API_URL}/api/customer/reviews/reviews_list`)
            if (response.data.status) {
                setReviewsList(response.data.data);
            } else {
                setReviewsList([]);
            }
        } catch (error) {
            console.log(error.message);
            setReviewsList([]);
        }
    }
    useEffect(() => {
        handle_reviews_api();
    }, [])

    function camel_Case(str) {
        if (!str || str === '') {
            return ''
        }
        return str[0].toUpperCase() + str.slice(1);
    }

    return (
        <>
            <div className="cardlike row mx-0 pb-4 mb-4 cms-testimonials">
                <div className="d-flex hdngrow m0 justify-content-between">
                    <h5>  Our Customers Says About {reviews?.page_name || reviews?.popular_city_name } { reviews?.trip_type } </h5>
                    <Link to={'/reviews'}>View All</Link>
                </div>
                <div className="row m0">
                    {
                        reviewsList.length > 0
                        && reviewsList.slice(0, 3).map((item, index) => {
                            return (
                                <div className="col-sm-4" key={index}>
                                    <div className='testsld'>
                                        <img src={quote} alt="quote" />
                                        <div className='sldtxt'>
                                            <p>{item.description}</p>
                                            <span className='clientname'>{item.remark_by}, {item.city_name === '' ? 'Lucknow' : item.city_name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}
export default Cms_review;
