import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../Config/Config';

const BannerImageContext = createContext();

export const useBannerImage = () => {
    const context = useContext(BannerImageContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
};

export const BannerImgProvider = ({ children }) => {
    const [config, setConfig] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchConfig = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${Config.API_URL}/api/customer/banner/list` , {app_type:'Website'} , {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': Config.API_ACCESS_TOKEN
                }
            });
            if (response.data.status) {
                setConfig(response.data.data);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    const value = {
        BannerImage:config,
        loading,
        error,
        refreshConfig: fetchConfig
    };

    return (
        <BannerImageContext.Provider value={value}>
            {children}
        </BannerImageContext.Provider>
    );
};