import React, { useCallback, useState, useContext, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import Config from "../../Config/Config";
import { SwapCityContext } from "../../Context/SwapContext";

const dropdownVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

const CitySuggestionsCMP = ({ handleFormatted, tripType, formData }) => {
  const { to_city, setTo_city, from_city, setFrom_city, swapStatus, setSwapStatus } =
    useContext(SwapCityContext);

  // Get default value from local storage
  const GetLocalDb = JSON.parse(localStorage.getItem("cab_listing")) || {};
  const defaultState = GetLocalDb.trip_type === tripType ? GetLocalDb.from_city : "";

  const [DropdownStatus, setDropDownStatus] = useState(false);
  const [cityInputValue, setCityInputValue] = useState(defaultState);
  const [fromCityId, setFromCityId] = useState("");
  const [from_state_name, setState_name] = useState("");
  const [from_state_id, set_From_State_id] = useState("");
  const [dbList, setDbList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const timerRef = useRef(null);

  // API call to fetch matching records
  const gatherMatchRecords = (trip_type, value) => {
    const Payloads = {
      keyword: value,
      page_no: "1",
      records_per_page: "10",
      trip_type: trip_type,
    };
    setIsLoading(true);
    axios
      .post(
        `${Config.API_URL}/api/customer/citylist`,
        JSON.parse(JSON.stringify(Payloads)),
        {
          headers: {
            Authorization: `${Config.API_ACCESS_TOKEN}`,
          },
        }
      )
      .then((respData) => {
        if (respData.data.status) {
          setDbList(respData.data.data);
        } else {
          setDbList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setDbList([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounced input change handler
  const handleCityInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setCityInputValue(value);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        gatherMatchRecords(tripType, value);
        setHighlightedIndex(0);
      }, 300);
    },
    [tripType]
  );

  // When a suggestion is selected (via click or keyboard)
  const handleSuggestionClick = useCallback(
    (suggestion) => {
      const city_name = `${suggestion.city_name}, ${suggestion.state_name}`;
      setFromCityId(suggestion._id);
      setFrom_city(city_name);
      setCityInputValue(city_name);
      setState_name(suggestion.state_name);
      set_From_State_id(suggestion.state_id);
      setDbList([]);
      handleFormatted({
        from_city: city_name,
        from_city_id: suggestion._id,
        from_state_id: suggestion.state_id,
        from_state_name: suggestion.state_name,
      });
      setDropDownStatus(false);
    },
    [handleFormatted, setFrom_city]
  );

  // Keyboard navigation for suggestions
  const handleKeyDown = (e) => {
    if (!DropdownStatus) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev + 1) % dbList.length);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev === 0 ? dbList.length - 1 : prev - 1));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (dbList[highlightedIndex]) {
        handleSuggestionClick(dbList[highlightedIndex]);
      }
    } else if (e.key === "Escape") {
      setDropDownStatus(false);
    }
  };

  // Swap logic if swapStatus is true
  useEffect(() => {
    if (swapStatus) {
      const swapedCity =
        cityInputValue === from_city ? to_city : cityInputValue === to_city ? from_city : cityInputValue;
      setCityInputValue(swapedCity);
      setSwapStatus(false);
    }
  }, [swapStatus, from_city, to_city, cityInputValue, setSwapStatus]);

  return (
    <Form.Group className="w-20 position-relative" controlId="">
      <Form.Label>Pickup</Form.Label>
      <LocationOnIcon />
      <Form.Control
        type="text"
        placeholder="Enter the pickup place"
        onChange={handleCityInputChange}
        value={cityInputValue}
        onFocus={() => {
          setDropDownStatus(true);
          gatherMatchRecords(tripType, '');
        }}
        onBlur={() => setTimeout(() => setDropDownStatus(false), 400)}
        onKeyDown={handleKeyDown}
      />
      <AnimatePresence>
        {DropdownStatus && (
          <motion.div
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="position-absolute p-2 w-100 custom-scrollbar"
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              zIndex: 1000,
            }}
          >
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center p-3">
                <Spinner animation="border" size="sm" />
              </div>
            ) : dbList.length > 0 ? (
              dbList.map((value, index) => (
                <>
                <div
                  key={value._id}
                  onMouseDown={() => handleSuggestionClick(value)}
                  className={`p-2 cursor-pointer ${index === highlightedIndex ? "bg-primary text-white" : "hover:bg-light"}`}
                >
                  {value.city_name}, {value.state_name}
                </div>
                <hr />
                </>
              ))
            ) : (
              <div className="p-3 text-muted">No results found</div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Form.Group>
  );
};

export default CitySuggestionsCMP;
