import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../Config/Config";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Popper } from "@mui/material";

const CustomPopper = (props) => (
  <Popper 
    {...props} 
    style={{ zIndex: 99999 }} 
  />
);


const PackagesDrop = ({ setPackagesValues, handleFormatted, cityName }) => {
  const LocalPackages = JSON.parse(localStorage.getItem("cab_listing")) || {};
  const [selectedOption, setSelectedOption] = useState(
    LocalPackages.package ? { package_name: LocalPackages.package } : null
  );
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading , setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(LocalPackages.package || "");
  const [packagesDB, setPackagesDB] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(
      LocalPackages.package ? { package_name: LocalPackages.package } : null
    );
  }, [LocalPackages.package]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleOptionClick = (event, value) => {
    if (!value) {
      setSelectedOption(null);
      setPackagesValues({ package: "", package_id: "" });
      handleFormatted({ package: "", package_id: "" });
      return;
    }
    setSelectedOption(value);
    setPackagesValues({ package: value.package_name, package_id: value._id });
    handleFormatted({ package: value.package_name, package_id: value._id });
  };

  useEffect(() => {
    let active = true;
    (async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // For demo purposes.
      if (active) {
        setOptions([...packagesDB]);
      }
    })();
    return () => {
      active = false;
    };
  }, [packagesDB]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const GetThePackagesAPIDate = (cityId) => {
    setLoading(true);
    axios
      .get(`${Config.API_URL}/api/customer/packagelist?from_city_id=${cityId}`, {
        headers: { Authorization: `${Config.API_ACCESS_TOKEN}` },
      })
      .then((response) => {
        if (response.data.status) {
          setPackagesDB(response.data.data);
          setLoading(false);
        } else {
          setPackagesDB([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setPackagesDB([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (cityName?.from_city_id) {
      const timer = setTimeout(() => {
        GetThePackagesAPIDate(cityName.from_city_id);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [cityName]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        style={{ backgroundColor: "#E0EBFF", }}
        size="small"
        open={open}
        onOpen={() => {
          if (!cityName?.from_city_id) {
            setSnackbarOpen(true);
            return;
          }
          setOpen(true);
        }}
        onClose={() => setOpen(false)}
        value={selectedOption}
        getOptionLabel={(option) => option.package_name || ""}
        options={options}
        loading={loading}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={(event, value) => handleOptionClick(event, value)}
        PopperComponent={CustomPopper} // Use custom Popper with high zIndex
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Packages List"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: "100%"  , zIndex: 999999999999 }}>
          Please select the city first
        </Alert>
      </Snackbar>
    </>
  );
};

export default PackagesDrop;
