import React, { useEffect, useRef, useState } from "react";
import adbnr from "../src/images/adbnr.png";
import blg1 from "../src/images/blgg1.png";
import blg2 from "../src/images/blgg2.png";

import Cms_review from './Cms-review';
import Call_booknow from './Call-booknow';
import Blogbox from './Blogbox';

import Footer from './Footer';
import Pop_routes from './Popular-routes';
import Side_quick from './Side_quick';
import axios from "axios";
import Config from "./Config/Config";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button } from "react-bootstrap";
import Faqs from "./Faq";
import { useConfig } from "./Context/ConfigContext";

const Blogs = () => {

    const [blogs, setBlogs] = useState(null);
    const [slugDetails, setSlugs] = useState(false);
    const { config, loading, error } = useConfig();
    

    const sectionRefs = useRef([]);

    const { id } = useParams()

    const fetchBlogsList = async () => {

        try {

            let payloads = {
                "page_no": '1',
                "per_page_record": '6',
                "scope_fields": ['content', 'meta_h_one_tag', 'page_slug']
            }

            let response = await axios.post(`${Config.API_URL}/api/customer/blog/list`, payloads)

            if (response.status === 200) {

                setBlogs(response.data.data)

            } else {
                setBlogs(null);
            }


        } catch (error) {

            console.log(error?.message);

        }
    }

    const fetchBlogsBySlug = async (slug) => {

        try {

            let payloads = {
                "slug": slug,
                "scope_fields": ['content', 'meta_h_one_tag', 'page_slug']
            }

            let response = await axios.post(`${Config.API_URL}/api/customer/blog/slug`, payloads)

            if (response.status === 200) {

                setSlugs(response.data.data)

            } else {
                setSlugs(null);
            }


        } catch (error) {


            console.log(error?.message);

        }

    }

    const scrollToSection = (index) => {
        if (index === 0) {
            // Scroll to the top of the page for the first article
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (sectionRefs.current[index]) {
            // Scroll to the respective section for other articles
            sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
        }
    };



    useEffect(() => {

        fetchBlogsList()

    }, [])


    useEffect(() => {

        if (id) {
            fetchBlogsBySlug(id)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }, [id])

    const productSchema = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": slugDetails?.meta_title || '',
        "image": slugDetails?.content?.[0]?.article_image ? Config.IMG + slugDetails?.content?.[0]?.article_image : Config.IMG + slugDetails?.content?.[0]?.article_image,
        "description": slugDetails.meta_description || '',
        "brand": {
            "@type": "Brand",
            "name": "Buzzway"
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "bestRating": "5",
            "worstRating": "3",
            "ratingCount": "2497"
        }
    }


    return (
        <>

            <Helmet>
                <title>{slugDetails?.meta_title}</title>
                <meta name="description" content={slugDetails?.meta_description} />
                <meta name="keywords" content={slugDetails?.meta_keyword} />

                <meta property="og:title" content={slugDetails?.meta_title} />
                <meta property="og:url" content={`${Config.BASE_URL}` + Location.pathname} />
                <meta property="og:type" content="article" />
                <meta property="og:description" content={slugDetails?.meta_description || slugDetails?.meta_description} />
                <meta property="og:keywords" content={slugDetails?.meta_keyword} />
                <meta property="og:image" content={slugDetails?.content?.[0]?.article_image ? Config.IMG + slugDetails?.content?.[0]?.article_image : ''} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta name="msvalidate.01" content="9D36FAD1457C3E2D065F32D20C0823D5" />
                <meta name="p:domain_verify" content="4c8587df519cc06075b55ae06c613007" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo.png" />
                {/* Twitter OG */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={slugDetails?.meta_title} />
                <meta name="twitter:description" content={slugDetails?.meta_description || slugDetails?.meta_description} />
                <meta name="twitter:keywords" content={slugDetails?.meta_keyword} />
                <meta name="twitter:url" content={`${Config.BASE_URL}` + Location.pathname} />
                <meta name="twitter:image" content={slugDetails?.content?.[0]?.article_image ? Config.IMG + slugDetails?.content?.[0]?.article_image : ''} />
                <link rel="canonical" href={`${Config.BASE_URL}` + Location.pathname} />

                <script type="application/ld+json">
                    {JSON.stringify(productSchema)}
                </script>

            </Helmet>

            <div className="container">
                <div className="row  py-4">
                    <div className="col-sm-9">
                        <div className="blgdtl_content">
                            <h3 className="sitehdngs">{slugDetails?.meta_h_one_tag}</h3>
                            <div className="blgdtlimg"
                                ref={(el) => (sectionRefs.current[0] = el)}
                            >
                                <img src={Config.IMG + slugDetails?.content?.[0]?.article_image} alt={slugDetails?.content?.[0]?.article_image_alt} height={450} />
                                <span>Fig. 1 {slugDetails?.city_name}</span>
                            </div>
                            <div className="table_content">
                                <div class="table_contents">
                                    <h5>In this Article</h5>
                                    <ul>

                                        {
                                            slugDetails && slugDetails?.content?.map((item, index) => {

                                                return (
                                                    <>
                                                        <li>
                                                            <Link onClick={() => scrollToSection(index)} >
                                                                {item?.article_title}
                                                            </Link>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                            <div className="blg_dtltext">

                                <h6> {slugDetails && slugDetails?.content?.[0]?.article_title} </h6>

                                <p dangerouslySetInnerHTML={{ __html: slugDetails && slugDetails?.content?.[0]?.article_content }}></p>

                            </div>

                            {
                                slugDetails && slugDetails?.content?.map((item, index) => {

                                    if (index === 0) return null;

                                    return (
                                        <>

                                            <div className="blgdtlimg"
                                                ref={(el) => (sectionRefs.current[index] = el)}
                                            >
                                                <img src={Config.IMG + item?.article_image} alt={item?.article_image_alt} height={450} />
                                                <span>Fig. {index+1} {slugDetails?.city_name}</span>
                                            </div>

                                            <div className="blg_dtltext">

                                                <h6> {item?.article_title} </h6>

                                                <p dangerouslySetInnerHTML={{ __html: item?.article_content }}></p>

                                            </div>

                                        </>
                                    )
                                })
                            }
                        </div>

                        {/* may be use in future */}

                        {/* <div className="bookcabs">
                            <h5>Book Cab from New Delhi</h5>
                        </div> */}

                        <Faqs  faq_data={slugDetails} />


                        <div className="moreblogs cardlike px-2 mb-4 ">

                            <div className="d-flex hdngrow m0 justify-content-between">
                                <h5>Explore More Blogs</h5>
                                <Link to={'/blogs'}>View All</Link>
                            </div>

                            <div className="row">
                                {
                                    blogs && blogs?.length > 0 &&
                                    blogs?.map((blog, index) => {
                                        return <Blogbox blog={blog} />
                                    })
                                }
                            </div>
                        </div>

                        <Cms_review />
                        <Call_booknow />
                        <div className="midbnr">
                            <a href={config?.app_link}> <img src={adbnr} alt="imageTag" /></a>
                        </div>
                    </div>
                    <div className="col-sm-3 cmssidebr">
                        <Side_quick />
                    </div>
                </div>
            </div>

            <Pop_routes />
            <Footer />
        </>
    )
}


export default Blogs;
