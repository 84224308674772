import React, { useCallback, useState, useContext, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { Dropdown } from "react-bootstrap";
import Config from "../../Config/Config";
import { SwapCityContext } from "../../Context/SwapContext";

const dropdownVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

const OneWayCitySuggetion = ({ handleFormatted, tripType, formData }) => {
  const { to_city, setTo_city, from_city, setFrom_city, swapStatus, setSwapStatus } =
    useContext(SwapCityContext);

  // Retrieve initial value from local storage
  let GetLocalDb = JSON.parse(localStorage.getItem("cab_listing")) || {};
  let defaultState = GetLocalDb.trip_type === tripType ? GetLocalDb.to_city : "";

  // Component state
  const [DropdownStatus, setDropDownStatus] = useState(false);
  const [cityInputValue, setCityInputValue] = useState(defaultState);
  const [fromCityId, setFromCityId] = useState("");
  const [dbList, setDbList] = useState([]);
  const [from_state_name, setState_name] = useState("");
  const [from_state_id, set_from_state_id] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  
  // Ref for debouncing the API call
  const timerRef = useRef(null);

  // API call to fetch city suggestions
  const gatherMatchRecords = (trip_type, value) => {
    const Payloads = {
      keyword: value,
      page_no: "1",
      records_per_page: "10",
      trip_type: trip_type
    };

    setIsLoading(true);
    axios
      .post(
        `${Config.API_URL}/api/customer/citylist`,
        JSON.parse(JSON.stringify(Payloads)),
        {
          headers: {
            Authorization: `${Config.API_ACCESS_TOKEN}`
          }
        }
      )
      .then((respData) => {
        if (respData.data.status) {
          setDbList(respData.data.data);
          setHighlightedIndex(0); // Reset index when new data arrives
        } else {
          setDbList([]);
        }
      })
      .catch((error) => {
        setDbList([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle input changes with debounce
  const handleCityInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setCityInputValue(value);
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        gatherMatchRecords(tripType, value);
        setFromCityId("");
      }, 300);
    },
    [tripType]
  );

  // Handle suggestion selection
  const handleSuggestionClick = useCallback(
    (suggestion) => {
      const city_name = `${suggestion.city_name}, ${suggestion.state_name}`;
      setFromCityId(suggestion._id);
      setTo_city(city_name);
      setCityInputValue(city_name);
      setState_name(suggestion.state_name);
      set_from_state_id(suggestion.state_id);
      setDbList([]);
      handleFormatted({
        to_city: city_name,
        to_city_id: suggestion._id,
        to_state_id: suggestion.state_id,
        to_state_name: suggestion.state_name
      });

      setDropDownStatus(false)
    },
    [handleFormatted, setTo_city]
  );

  // Handle keyboard navigation (arrow keys, Enter, Escape)
  const handleKeyDown = (e) => {
    if (!DropdownStatus) return;

    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (dbList.length > 0) {
        setHighlightedIndex((prev) => (prev + 1) % dbList.length);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (dbList.length > 0) {
        setHighlightedIndex((prev) => (prev === 0 ? dbList.length - 1 : prev - 1));
      }
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (dbList[highlightedIndex]) {
        handleSuggestionClick(dbList[highlightedIndex]);
      }
    } else if (e.key === "Escape") {
      setDropDownStatus(false);
    }
  };

  // Swap cities if swapStatus is true
  useEffect(() => {
    if (swapStatus) {
      let swapedCity =
        cityInputValue === from_city ? to_city : cityInputValue === to_city ? from_city : cityInputValue;
      setCityInputValue(swapedCity);
      handleFormatted({
        to_city: formData.from_city,
        to_city_id: formData.from_city_id,
        to_state_id: formData.from_state_id,
        to_state_name: formData.from_state_name,
        from_city: formData.to_city,
        from_city_id: formData.to_city_id,
        from_state_id: formData.to_state_id,
        from_state_name: formData.to_state_name
      });
      setSwapStatus(false);
    }
  }, [swapStatus, from_city, to_city, cityInputValue, handleFormatted, setSwapStatus, formData]);

  return (
    <>
      <Form.Group className="w-20 position-relative" controlId="">
        <Form.Label>Drop</Form.Label>
        <LocationOnIcon />
        <Form.Control
          type="text"
          placeholder="Enter the pickup place"
          onChange={handleCityInputChange}
          value={cityInputValue}
          onFocus={() => {
            setDropDownStatus(true);
            gatherMatchRecords(tripType, '');
          }}
          onBlur={() => setTimeout(() => setDropDownStatus(false), 400)}
          onKeyDown={handleKeyDown}
        />
        <AnimatePresence>
          {DropdownStatus && (
            <motion.div
              variants={dropdownVariants}
              initial="hidden"
              animate="visible"
              exit="exit" 
              className="position-absolute p-2 w-100 custom-scrollbar"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                zIndex: 99999999,
              }}
            >
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center p-3">
                  <Spinner animation="border" size="sm" />
                </div>
              ) : dbList.length > 0 ? (
              dbList.map((value, index) => (
                <>
                <div
                  key={value._id}
                  onMouseDown={() => handleSuggestionClick(value)}
                  className={`p-2 cursor-pointer ${index === highlightedIndex ? "bg-primary text-white" : "hover:bg-light"}`}
                >
                  {value.city_name}, {value.state_name}
                </div>
                  <hr />
                  </>
                ))
              ) : (
                <div className="p-3 text-muted">No results found</div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </Form.Group>
    </>
  );
};

export default OneWayCitySuggetion;
