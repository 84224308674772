import React, { useState, useEffect } from "react";
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import Config from "./Config/Config";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { SyncLoader } from "react-spinners";
import { useConfig } from "./Context/ConfigContext";
import { useLocation } from "react-router-dom";


const RefundPolicy = () => {
    const [RefundPolicy, setRefundPolicy] = useState({});
    const [loading, setLoading] = useState(false);
    const { config, error } = useConfig();
    const Location = useLocation();




    const handleAboutUsAPI = async () => {
        try {
            let Payloads = {
                page_type: 'refund-policy'
            }
            setLoading(true);
            let response = await axios.post(`${Config.API_URL}/api/customer/common/cms/list`, Payloads);
            if (response.data.status) {
                setRefundPolicy(response.data.data[0]);
                setLoading(false);
            } else {
                setRefundPolicy([]);
                setLoading(false);
            }
        } catch (error) {
            setRefundPolicy([]);
            setLoading(false);
        }
    }

    // here handle the about use page
    useEffect(() => {
        handleAboutUsAPI();
    }, [])

    // handle the scroll bar
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, []);

    const BassnessSchema = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Buzzway",
        "image": config?.website_logo ? `${Config.IMG}` + config.website_logo : `${Config.BASE_URL}/logo.png`,
        "@id": RefundPolicy?.banner_image_jpeg ? `${Config.IMG}` + RefundPolicy?.banner_image_jpeg : `${Config.BASE_URL}/logo.png`,
        "url": "https://www.buzzway.in/",
        "telephone": "+919054865866",
        "priceRange": "0",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": ", Shital House, near Denny Coffee Bar, opp. New York Tower A, Thaltej",
            "addressLocality": "Ahmedabad",
            "postalCode": "360001",
            "addressCountry": "IN"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 23.0536039,
            "longitude": 72.5195122
        },
        "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
        },
        "sameAs": [
            "https://www.buzzway.in/",
            "https://in.pinterest.com/Buzzwaycar/",
            "https://www.linkedin.com/in/buzzway/",
            "https://youtube.com/channel/UCmk1a_siIybosrgdJrHCcww",
            "https://www.instagram.com/buzzwaytaxiservice/",
            "https://www.facebook.com/buzzway01/",
            "https://twitter.com/Buzzway01",
            "https://www.tumblr.com/buzzwaytaxi"
        ]
    };


    return (
        <>
            {loading ?
                <Row>
                    < Col xs={12} md={12} lg={12} className="text-center mt-5">
                        <SyncLoader />
                    </Col >
                </Row > :
                Object.entries(RefundPolicy).length > 0 &&
                <>
                    <Helmet>
                        <title>{RefundPolicy.meta_title}</title>
                        <meta name="title" content={Object.entries(RefundPolicy).length > 0 && RefundPolicy.meta_title} />
                        <meta name="description" content={Object.entries(RefundPolicy).length > 0 && RefundPolicy.meta_description} />
                        <meta name="keywords" content={Object.entries(RefundPolicy).length > 0 && RefundPolicy.meta_keyword} />

                        <link rel="canonical" href={`${Config.BASE_URL}` + Location.pathname} />

                        <meta property="og:title" content={RefundPolicy?.h_one_tag} />
                        <meta property="og:url" content={`${Config.BASE_URL}` + Location.pathname} />
                        <meta property="og:description" content={RefundPolicy.meta_description} />
                        <meta property="og:keywords" content={RefundPolicy.meta_keyword} />
                        <meta property="og:image" content={`${Config.IMG}` + RefundPolicy?.banner_image_jpeg} />

                        <script type="application/ld+json">
                            {JSON.stringify(BassnessSchema)}
                        </script>

                    </Helmet>
                    <div className="full_txttop bgdarkblue">
                        <div className="container py-4">
                            <h1>{Object.entries(RefundPolicy).length > 0 && RefundPolicy.h_one_tag}</h1>
                        </div>
                    </div>
                    <div className="container py-4">
                        <div className="row">
                            <div className="abttxt" dangerouslySetInnerHTML={{ __html: Object.entries(RefundPolicy).length > 0 && RefundPolicy.content_data }} />
                        </div>
                    </div>

                    <Pop_routes />

                    <Footer />
                </>
            }
        </>
    )
}


export default RefundPolicy;

