import React from "react";
import blg1 from "../src/images/blg1.png";
import { Link } from "react-router-dom";
import Config from "./Config/Config";

const Blogbox = ({ blog }) => {


    return (
        <>
            <div className="col-sm-4" key={blog?._id}>
                <div className="blogbox" key={blog?._id}>
                    <Link to={`/blogs/${blog?.page_slug}`}>
                        <div className="blgimg">
                            <img src={`${Config.IMG}${blog?.content?.[0]?.article_image}`} alt={blog?.content?.[0]?.article_image_alt} height={250} width={100}/>
                        </div>
                        <div className="blgtxt">
                            <h5>{blog?.meta_h_one_tag}</h5>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}


export default Blogbox;
