import React from "react";
import logo from "../src/images/logo.png";
import fb from "../src/images/facebook.svg";
import insta from "../src/images/insta.svg";
import twitter from "../src/images/twitter.svg";
import yt from "../src/images/yt.svg";
import copyrightline from "../src/images/copyright-line.svg";
import { Link } from "react-router-dom";
import moment from 'moment'
import Config from "./Config/Config";



const Footer = () => {
    return (
        <>
            <footer className="bgblack">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 logo-email">
                            <img src={logo} alt="footer-logo" />
                            <p style={{color:'white'}}>Connect: <a style={{color:'#F4B045'}} href="">booking@buzzway.in</a></p>
                        </div>
                        <div className="col-sm-7 footrmenu topMargin11x">
                            <ul className="row">
                                {/* <li><a href="/about-us">About Us</a></li> */}
                                <li className="col-sm-3"><Link to={`/about-us`}>About Us</Link></li>
                                <li className="col-sm-3"><Link to={`/contact-us`}>Contact Us</Link></li>
                                <li className="col-sm-2"><Link to={`/drive-with-us`}>Drivers</Link></li>
                                <li className="col-sm-3"><Link to={`/terms-conditions`}>Terms & Conditions</Link></li>
                            </ul>
                            <ul className="row">
                                {/* <li><a href="/about-us">About Us</a></li> */}
                                <li className="col-sm-3"><Link to={`/privacy-policy`}>Privacy Policy</Link></li>
                                <li className="col-sm-3"><Link to={`/refund-Policy`}>Refund Policy</Link></li>
                                <li className="col-sm-2"><Link to={`/blogs`}>Blogs</Link></li>
                                <li className="col-sm-3"><a href={`/sitemap.xml`}>Site Map</a></li>
                            </ul>
                        </div>
                        <div className="col-sm-2 social topMargin11x">
                            <ul>
                                <li> <a href="https://www.facebook.com/buzzway01/"><img src={fb} /> </a> </li>
                                <li> <a href="https://www.instagram.com/buzzwaytaxiservice/"><img src={insta} /> </a> </li>
                                <li> <a href="https://twitter.com/buzzway01/"><img src={twitter} /> </a> </li>
                                <li> <a href="https://www.youtube.com/channel/UCmk1a_siIybosrgdJrHCcww"><img src={yt} /> </a> </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="row footrmenu">
                        <ul>
                            <li><Link to={`/about-us`}>About Us</Link></li>
                            <li><Link to={`/contact-us`}>Contact Us</Link></li>
                            <li><Link to={`/register`}>Drivers</Link></li>
                            <li><Link to={`/terms-conditions`}>Terms & Conditions</Link></li>
                            <li><Link to={`/privacy-policy`}>Privacy Policy</Link></li>
                            <li><Link to={`/refund-Policy`}>Refund Policy</Link></li>
                            <li><Link to={`/blogs`}>Blogs</Link></li>
                            <li><a href={`/sitemap.xml`}>Site Map</a></li>
                        </ul>
                    </div> */}
                    <div className="row">
                        <div className="copyr">
                            <p> <img src={copyrightline} /> {moment().year()} | All Rights Reserved by Buzzway. Promoted and Design By <a style={{color:'#F4B045'}} href="https://www.duplextech.com/"> Duplex Technologies</a></p>
                        </div>
                    </div>
                </div>
                <div class="mx-0 callbknow footer_fixed">
                    <div class="">
                        <a href="tel:+91-9054865866">
                            <div class="d-flex align-items-center">
                                <a href="#">
                                  <img src="/static/media/bn.468c1651b8b45b8bdae247ccfd1329ba.svg" alt="cab Book"/>                
                                </a>
                            </div>
                        </a>
                    </div>
                    <div class="">
                                <a href="tel:+91-9054865866" class="cbntxt ylwbtn">+91-9054865866</a>
                    </div>
                    <div class="">
                        <div class="d-flex align-items-center">
                          <a href="#" class="cbntxt">
                             <img src="/static/media/whatsapp.ee60bfac4c0ac027d77fbe6f8fee425b.svg" alt="what app images" />
                          </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer; 