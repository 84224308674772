import React, { useEffect, useState } from "react";
// import regsbnr from "../src/images/regsbnr.png";
import adbnr from "../src/images/adbnr.png";
import ContactBanner from "../src/images/ContactUsImages.jpg";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Download_apps from './Download-apps';

import Home_testimonial from './Home-testimonial';
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import AlertPopupMessage from "./ErrorPopus/AlertPopus";
import { Helmet } from "react-helmet-async";
import ContactUsForm from "./ContactUsForm";
import Config from "./Config/Config";
import axios from 'axios'
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaEnvelope, FaMapMarkerAlt, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { useConfig } from "./Context/ConfigContext";
import { TfiEmail } from "react-icons/tfi";
import { LuPhoneCall } from "react-icons/lu";





const ContactUs = () => {

    const { config, loading, error } = useConfig();


    console.log(config, 'this is Config Details Here');


    const [ContactUs, setContactUs] = useState({});
    const Location = useLocation();

    const [metaData, setMetaData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keyword: ''
    });      

    const handleContactUsAPI = async () => {
        try {
            let Payloads = {
                page_type: 'contact-us'
            }
            let response = await axios.post(`${Config.API_URL}/api/customer/common/cms/list`, Payloads);
            if (response.data.status) {
                setContactUs(response.data.data[0]);
            } else {
                setContactUs([]);
            }
        } catch (error) {
            setContactUs([]);

        }
    }

    // here handle the Contact us page
    useEffect(() => {
        handleContactUsAPI();
    }, [])

    // handle the meta description & title here
    useEffect(() => {
        setMetaData({
            meta_title: ContactUs.meta_title,
            meta_description: ContactUs.meta_description,
            meta_keyword: ContactUs.meta_keyword
        });
    }, [ContactUs.meta_description, ContactUs.meta_keyword, ContactUs.meta_title])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <>
            <AlertPopupMessage />
            <Helmet>
                <title>{metaData.meta_title}</title>
                <meta name="description" content={metaData.meta_description} />
                <meta name="keywords" content={metaData.meta_keyword} />
                <link rel="canonical" href={`${Config.BASE_URL}` + Location.pathname} />

            </Helmet>
            <div className="fullbnr">
                <img src={ContactBanner} alt="Banner imag" />
                <div className="ovrlylefttext ovrlytext">
                    <h1> {ContactUs.h_one_tag || "Loading..."} </h1>
                </div>
            </div>
            <div className="container">
                <div className="row py-5">
                    <div className="col-sm-12">
                        <div className="abttxt" dangerouslySetInnerHTML={{ __html: Object.entries(ContactUs).length > 0 && ContactUs.content_data || 'Loading...' }} />
                        <div className="row">

                            <Container className="py-5">
                                <Row>
                                    {config && config?.corporate_address.map((contact, index) => (
                                        <Col md={12} sm={12} data-aos="fade-up" key={index}>
                                            <Card className="my-3 text-white" style={{  backgroundColor: index % 2 === 0 ?  "#F4B045" : "#0d6efd" }}>
                                                <Card.Body className="text-center d-flex justify-content-around flex-wrap">
                                                    <div className="icon-circle text-center" style={{ width: "30%" }}>
                                                        <TfiEmail size={30} style={{ color: index % 2 === 0 ?  "#0d6efd" : "#F4B045"  }} />
                                                        <p className="info-title mt-2">
                                                            <a href={`mailto:${contact.email}`} className="text-decoration-none text-white">
                                                                {contact.email}
                                                            </a>
                                                        </p>
                                                    </div>

                                                    <div className="icon-circle text-center" style={{ width: "30%" }}>
                                                        <LuPhoneCall    size={30} style={{ color: index % 2 === 0 ?   "#0d6efd" : "#F4B045"  }} />
                                                        <p className="info-title mt-2">
                                                            <a href={`tel:${contact.phone}`} className="text-decoration-none text-white">
                                                                {contact.mobile_number}
                                                            </a>
                                                        </p>
                                                    </div>

                                                    <div className="icon-circle text-center" style={{ width: "40%" }}>
                                                        <FaMapMarkerAlt size={30} style={{ color: index % 2 === 0 ?  "#0d6efd" : "#F4B045"  }} />
                                                        <p className="info-title mt-2">{contact.office_address}</p>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>

            <Home_testimonial />
            <Download_apps />

            <Pop_routes />

            <Footer />
        </>
    )
}


export default ContactUs;
