import React, { useState, useContext, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import AlarmIcon from "@mui/icons-material/Alarm";
import moment from "moment";
import { AlertContext } from "../../Context/AlertsPopusContext";
import 'rc-time-picker/assets/index.css';
import './timeInput.css';
import { AnimatePresence, motion } from "framer-motion";

const dropdownVariants = {
  hidden: { opacity: 0, y: -10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

const TimePickerInput = ({ handleFormatted, selectedDateInput, tripType }) => {
  const { setMessage, setStatus } = useContext(AlertContext);
  const [intervals, setIntervals] = useState([]);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const dbData = JSON.parse(localStorage.getItem("cab_listing")) || {};
  const format = "h:mm A";
  const [time, setTime] = useState("");
  const containerRef = useRef(null);

  // Set initial time from localStorage if available
  useEffect(() => {
    if (dbData.trip_type === tripType) {
      const selectedTime = dbData.pickup_time ? dbData.pickup_time : "";
      setTime(selectedTime);
    }
  }, [dbData, tripType]);

  const TimeHandler = (timeValue, date) => {
    const formattedTime = timeValue;
    if (!moment(timeValue, format, true).isValid()) {
      console.error("Invalid time value");
      return;
    }
    const selectedDate = moment(date);
    const currentTime = moment();
    const isToday = selectedDate.isSame(currentTime, "day");

    if (isToday) {
      const futureTime = moment(currentTime).add(2, "hours");
      if (moment(formattedTime, format).isBefore(futureTime)) {
        setMessage({
          status: false,
          message: `Please select a time at least 2 hours from now`
        });
        setStatus(true);
        const timer = setTimeout(() => {
          setStatus(false);
        }, 3000);
        setTime(timeValue);
        return () => clearTimeout(timer);
      }
    }

    setTime(timeValue);
    handleFormatted({ pickup_time: formattedTime });
  };

  useEffect(() => {
    const selectedDate = moment(selectedDateInput).startOf("day");
    const dbPickupDate = moment(dbData.pickup_date ? dbData.pickup_date : "").startOf("day");
    if (!selectedDate.isSame(dbPickupDate, "day")) {
      setTime("");
    } else {
      setTime(dbData.pickup_time ? dbData.pickup_time : "");
    }
  }, [dbData.pickup_date, dbData.pickup_time, selectedDateInput]);

  // Function to render the time panel dropdown
  const renderTimePanel = (pickupDate) => {
    const arrays = [];
    let startTime;
    const endTime = moment().endOf("day");
    setDropdownStatus(true);
    if (moment(pickupDate).isSame(moment(), "day")) {
      startTime = moment().add(2, "hours");
    } else {
      startTime = moment().startOf("day");
    }
    while (startTime.isBefore(endTime)) {
      arrays.push(startTime.format(format));
      startTime.add(30, "minutes");
    }
    setIntervals(arrays);
  };

  const handleSuggestionClick = (value) => {
    setTime(value);
    TimeHandler(value, selectedDateInput);
    setDropdownStatus(false);
    handleFormatted({ pickup_time: value });
  };

  // onBlur handler to close dropdown when focus leaves the container
  const handleBlur = (e) => {
    // If the newly focused element is not a child of the container, close the dropdown
    if (!containerRef.current.contains(e.relatedTarget)) {
      setDropdownStatus(false);
    }
  };

  return (
    <Form.Group
      className="w15 position-relative"
      controlId=""
      ref={containerRef}
      onBlur={handleBlur}
    >
      <Form.Label>Time</Form.Label>
      <AlarmIcon />
      <input
        className="custome_input_css"
        type="text"
        value={time}
        onFocus={() => renderTimePanel(selectedDateInput)}
        onChange={(e) => TimeHandler(e.target.value, selectedDateInput)}
        placeholder="Select time"
        readOnly
      />
      <AnimatePresence>
        {dropdownStatus && (
          <motion.div
            variants={dropdownVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="position-absolute p-2 w-100 custom-scrollbar"
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              zIndex: 999999999,
              width: "100%",
              maxHeight: "300px",
              overflowY: "auto"
            }}
          >
            {intervals.length > 0 &&
              intervals.map((value, index) => (
                <div key={index}>
                  <div
                    onClick={() => handleSuggestionClick(value)}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    tabIndex={0}
                  >
                    {value}
                  </div>
                  {index !== intervals.length - 1 && <hr />}
                </div>
              ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Form.Group>
  );
};

export default TimePickerInput;
