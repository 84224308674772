import React, { useEffect, useState } from "react";
import regsbnr from "../src/images/regsbnr.png";
import adbnr from "../src/images/adbnr.png";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Download_apps from './Download-apps';

import Home_testimonial from './Home-testimonial';
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import Register_form from './Register-form';
import AlertPopupMessage from "./ErrorPopus/AlertPopus";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Config from "./Config/Config";
import { useConfig } from "./Context/ConfigContext";
import { useLocation } from "react-router-dom";

const Register = () => {
    const [DriverCms, setDriverCms] = useState({});
    const { config, error } = useConfig();
    const Location = useLocation();


    const [metaData, setMetaData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keyword: ''
    });

    const FetchDriverCmsRecords = async () => {
        try {
            let Payloads = {
                page_type: 'drive-with-us'
            }
            let response = await axios.post(`${Config.API_URL}/api/customer/common/cms/list`, Payloads);
            if (response.data.status) {
                setDriverCms(response.data.data[0]);
            } else {
                setDriverCms({});
            }
        } catch (error) {
            setDriverCms({});
        }
    }

    useEffect(() => {
        setMetaData({
            meta_title: DriverCms.meta_title,
            meta_description: DriverCms.meta_description,
            meta_keyword: DriverCms.meta_keyword
        });
    }, [DriverCms.meta_description, DriverCms.meta_keyword, DriverCms.meta_title])


    useEffect(() => {
        FetchDriverCmsRecords();
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, []);

    const BassnessSchema = {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Buzzway",
        "image": config?.website_logo ? `${Config.IMG}` + config.website_logo : `${Config.BASE_URL}/logo.png`,
        "@id": metaData?.banner_image_jpeg ? `${Config.IMG}` + metaData?.banner_image_jpeg : `${Config.BASE_URL}/logo.png`,
        "url": "https://www.buzzway.in/",
        "telephone": "+919054865866",
        "priceRange": "0",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": ", Shital House, near Denny Coffee Bar, opp. New York Tower A, Thaltej",
            "addressLocality": "Ahmedabad",
            "postalCode": "360001",
            "addressCountry": "IN"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 23.0536039,
            "longitude": 72.5195122
        },
        "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
        },
        "sameAs": [
            "https://www.buzzway.in/",
            "https://in.pinterest.com/Buzzwaycar/",
            "https://www.linkedin.com/in/buzzway/",
            "https://youtube.com/channel/UCmk1a_siIybosrgdJrHCcww",
            "https://www.instagram.com/buzzwaytaxiservice/",
            "https://www.facebook.com/buzzway01/",
            "https://twitter.com/Buzzway01",
            "https://www.tumblr.com/buzzwaytaxi"
        ]
    };

    return (
        <>
            <AlertPopupMessage />
            <Helmet>
                <title>{metaData.meta_title}</title>
                <meta name="description" content={metaData.meta_description} />
                <meta name="keywords" content={metaData.meta_keyword} />
                <link rel="canonical" href={`${Config.BASE_URL}` + Location.pathname} />

                <meta property="og:title" content={metaData?.h_one_tag} />
                <meta property="og:url" content={`${Config.BASE_URL}` + Location.pathname} />
                <meta property="og:description" content={metaData.meta_description} />
                <meta property="og:keywords" content={metaData.meta_keyword} />
                <meta property="og:image" content={`${Config.IMG}` + metaData?.banner_image_jpeg} />

                <script type="application/ld+json">
                    {JSON.stringify(BassnessSchema)}
                </script>
            </Helmet>
            <div className="fullbnr">
                <img src={regsbnr} alt={DriverCms?.h_one_tag || ""} loading="lazy" />
                <div className="ovrlylefttext ovrlytext">
                    <h1> {DriverCms?.h_one_tag || 'Loading...'} </h1>
                </div>
            </div>
            <div className="container">
                <div className="row py-5">
                    <div className="col-sm-7 order-mob-2">
                        <div className="abttxt" dangerouslySetInnerHTML={{ __html: Object.entries(DriverCms).length > 0 ? DriverCms.content_data : 'Loading...' }} />
                        <div className="regst_ftrs mt-3">
                            <div className="rgst_ftrbx">
                                <TaskAltIcon />
                                <p>250+ Cities</p>
                            </div>
                            <div className="rgst_ftrbx">
                                <TaskAltIcon />
                                <p>2k+ rides per month</p>
                            </div>
                            <div className="rgst_ftrbx">
                                <TaskAltIcon />
                                <p>Pan India Presence</p>
                            </div>
                            <div className="rgst_ftrbx">
                                <TaskAltIcon />
                                <p>175 million app install</p>
                            </div>
                        </div>
                        <div className="midbnr mt-3">
                            <img src={adbnr} alt="Downloads Our Apps" />
                        </div>
                    </div>
                    <div className="col-sm-5 order-mob-1">
                        <div className="regst_form_pos">
                            <div className="regst_form">
                                <h3>Register Now</h3>
                                <Register_form />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Home_testimonial />
            <Download_apps />

            <Pop_routes />

            <Footer />
        </>
    )
}


export default Register;
